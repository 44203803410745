import React, { Component } from "react"
import "../scss/policies.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { setDefaultHeaderTheme, navbarUpdate } from "../utils/common-util"

class AntiSlaverySPolicies extends Component {
  componentDidMount() {
    setDefaultHeaderTheme(true)
    navbarUpdate()
  }

  render() {

    return (
      <Layout>
        <SEO title="Anti-slavery Statement" />
        <div className="policy-contents iww-full-height">
          <div id="stars1" className="stars"></div>
          <div id="stars2" className="stars"></div>
          <div id="stars3" className="stars"></div>
          <div className="pc-page-wrap iww-container-mr">
            <div className="pc-main-head">
              INTEGRATION WIZARDS SOLUTIONS PVT LTD
              <br />
              Anti-Slavery Statement, 04/01/2021
            </div>
            <div className="pc-head">
              ORGANISATIONAL	STRUCTURE	&	SUPPLY	CHAIN
            </div>
            <div className="pc-para">
              This	statement	sets	out	the	Company's	actions	to	understand	all	
              potential	modern	slavery	risks	related	to	its	business	and	to	put	
              in	place	steps	that	are	aimed	at	ensuring	that	there	is	no	slavery	
              or	human	trafficking	in	its	own	business	and	its	supply	chains.
              <br />
              <br />
              The	Company	has	zero	tolerance	to	modern	slavery	and	human	
              trafficking	and	is	committed	to	acting	ethically	and	with	integrity	
              in	all	our	business	relationships	implementing	and	enforcing	
              effective	systems	and	controls	to	ensure	slavery	and	human	
              trafficking	is	not	taking	place	anywhere	in	our	supply	chains.
              <br />
              <br />
              The	Directors	are	responsible	for	compliance	in	their	respective	
              departments	and	for	their	supplier	relationships.
            </div>
            <div className="pc-head">DUE	DILIGENCE</div>
            <div className="pc-para">
              As	part	of	our	initiative	to	identify	and	mitigate	risk,	where	
              possible,	we	build	long	standing	relationships	with	local	
              suppliers	and	customers	and	make	clear	our	expectations	of	
              business	behaviour.
              <br />
              <br />
              With	regards	to	national	or	international	supply	chains,	our	point	
              of	contact	is	preferably	with	a	UK	Company	or	branch and	we	
              expect	these	entities	to	have	suitable	anti-slavery	and	human	
              trafficking	policies	and	processes.
            </div>
            <div className="pc-head">TRAINING</div>
            <div className="pc-para">
              To	ensure	a	level	of	understanding	of	the	risks	of	modern	slavery	
              and	human	trafficking	in	our	supply	chains	and	our	business,	we	
              provide	training	to	relevant	members	of	staff.	All	Directors	have	
              been	briefed	on	the	subject.
            </div>
            <div className="pc-head">MONITORING</div>
            <div className="pc-para">
              In	light	of	the	introduction	of	the	Modern	Slavery	Act	2015	we	
              use	the	following	key	performance	indicators	(KPIs)	to	measure	
              how	effective	we	have	been to	ensure	that	slavery	and	human	
              trafficking	is	not	taking	place	in	any	part	of	our	business	or	
              supply	chains:
            </div>
            <ol>
              <li>Ensure	the	supply	chain	understand	and	comply	with	our	expectations.</li>
              <li>Require	all	relevant	staff	to	have	completed	training	on	modern	slavery.</li>
            </ol>
            <div className="pc-head">STATUS</div>
            <div className="pc-para">
              This	statement	is	made	pursuant	to	section	54(1)	of	the	
              Modern	Slavery	Act	2015	and	constitutes	the	Company’s	
              Anti-Slavery	Statement	for	the	current	financial	year
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AntiSlaverySPolicies
